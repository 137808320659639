const paths = {
  account: "/account",
  login: "/login",
  register: "/register",
  checkout: "/checkout",
  cart: "/cart",
  pages: {
    termsAndConditions: {
      content: "/pages/terms-and-conditions/content",
    },
    privacyPolicy: {
      content: "/pages/privacy-policy/content",
    },
    deliveryInstructions: "pages/delivery-instructions/content",
    refundReturnPolicy: "pages/refund-return-policy/content",
  },
  products: {
    index: "/products",
  },
  wishList: "/wishlist",
  orderPlaced: "/order-placed",
  home: "/",
};

export default paths;
