<template>
  <div class="layout-wrapper flex w-full flex-col bg-white">
    <div
      class="flex !h-32 w-full items-center justify-start pl-12 md:!h-[12rem]"
    >
      <NuxtLink to="https://hbm.studio/" target="_blank">
        <img src="@/assets/images/HBM-logo.svg" alt="HBM logo" />
      </NuxtLink>
    </div>
    <div class="flex h-full items-start justify-center px-6 md:px-0">
      <div class="flex flex-col items-center justify-center 2xl:w-1/4">
        <div
          class="text-center font-[Nunito] text-[24px] font-bold text-[#101014] md:text-[30px]"
        >
          Welcome to HBM Product
        </div>
        <div
          class="text-center font-[Lato] text-[18px] font-[500] text-[#838383]"
        >
          Please enter your HBM credentials below
        </div>
        <div class="mt-10 w-full">
          <FormKit
            form-class="w-full"
            type="form"
            ref="form"
            id="tenant-login"
            :actions="false"
            @submit="submit"
          >
            <div class="mb-5 flex flex-col">
              <div
                class="mb-2 font-[Lato] text-base font-semibold text-[#101014]"
              >
                Email address
              </div>
              <FormKit
                data-cy="email-input"
                validation="required|email"
                type="text"
                input-class="!bg-[#F9F9F9] w-full "
                name="email"
                id="email"
                placeholder="Ex: abc@example.com"
              />
            </div>
            <div class="mb-5">
              <div
                class="mb-2 font-[Lato] text-base font-semibold text-[#101014]"
              >
                Password
              </div>
              <FormKit
                data-cy="password-input"
                validation="required"
                type="password"
                input-class="!bg-[#F9F9F9] w-full"
                suffix-icon="eyeClosed"
                @suffix-icon-click="handleIconClick"
                inner-class="relative"
                suffix-icon-class="cursor-pointer w-5 h-5 block absolute right-6 top-1/2 -translate-y-1/2"
                name="password"
                id="password"
                placeholder="Enter your password"
              />
            </div>
            <div class="mt-2">
              <FormKit
                :input-class="
                  ' rounded-[8px] text-white font-semibold text-[16px] bg-[#7622FF] py-4 mt-8 w-full font-[Raleway]' +
                  (loading ? 'loading' : '')
                "
                type="submit"
                label="Log in"
                message-class="list"
              />
            </div>
            <div class="mt-4 text-warning">{{ errors[0]?.message }}</div>
          </FormKit>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { TenantResponse } from "~/types/Common/tenant";

const errors = ref([]);
const loading = ref(false);
const handleIconClick = (node: any, e: any) => {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
};
async function submit(data: any) {
  const Version = useRuntimeConfig()?.public?.env?.VERSION;
  const tenantUrl = useRuntimeConfig()?.public?.tenatUrl;

  if (Version === "PREVIEW") {
    const { data: tenant, error } = await useFetch<TenantResponse>(
      `${tenantUrl}/api/v1/domain`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        }),
      },
    );

    if (tenant.value?.data.url !== null) {
      const baseUrlCookie = useCookie("baseUrl");
      // TODO: the tenant Base URL should include https from the backend
      baseUrlCookie.value = `https://${tenant.value?.data.url}`;

      useCookie("tenantAuth").value = "true";

      window.location.replace("/");
    } else if (error.value) {
      errors.value = error.value?.data.meta.errors;
    }
  }
}
</script>
