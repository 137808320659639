import { default as _404gLkzNPdHucMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/404.vue?macro=true";
import { default as accountj67uwJTvJgMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/account.vue?macro=true";
import { default as bundleswu3UeEZZKyMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue?macro=true";
import { default as cartZ52mKWnT4OMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/cart.vue?macro=true";
import { default as indexc57PL3wBoqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue?macro=true";
import { default as contactUsvA3rY0c5suMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue?macro=true";
import { default as _91id_93cNsoiHsbpqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue?macro=true";
import { default as faqsf1VX31ouVMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/faq.vue?macro=true";
import { default as indexY9Jn2MBYlRMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/index.vue?macro=true";
import { default as loginBdm4q57I8UMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/login.vue?macro=true";
import { default as order_45placedoG2q02WJFNMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue?macro=true";
import { default as contentpLiGnl4G3VMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failed882IBCKblCMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionVo9bCRwYg6Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue?macro=true";
import { default as _91id_93cXNeSq41H8Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue?macro=true";
import { default as indexcLhTb11Z4hMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue?macro=true";
import { default as registerma6WvvbFY5Meta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/register.vue?macro=true";
import { default as resetZjwCewCHReMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/reset.vue?macro=true";
import { default as staticlWrdbQlwIgMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/static.vue?macro=true";
import { default as verifyOmWjXVY23VMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/verify.vue?macro=true";
import { default as wishlistKpl0Nx3aTqMeta } from "/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue?macro=true";
export default [
  {
    name: _404gLkzNPdHucMeta?.name ?? "404",
    path: _404gLkzNPdHucMeta?.path ?? "/404",
    meta: _404gLkzNPdHucMeta || {},
    alias: _404gLkzNPdHucMeta?.alias || [],
    redirect: _404gLkzNPdHucMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accountj67uwJTvJgMeta?.name ?? "account",
    path: accountj67uwJTvJgMeta?.path ?? "/account",
    meta: accountj67uwJTvJgMeta || {},
    alias: accountj67uwJTvJgMeta?.alias || [],
    redirect: accountj67uwJTvJgMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundleswu3UeEZZKyMeta?.name ?? "bundles",
    path: bundleswu3UeEZZKyMeta?.path ?? "/bundles",
    meta: bundleswu3UeEZZKyMeta || {},
    alias: bundleswu3UeEZZKyMeta?.alias || [],
    redirect: bundleswu3UeEZZKyMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cartZ52mKWnT4OMeta?.name ?? "cart",
    path: cartZ52mKWnT4OMeta?.path ?? "/cart",
    meta: cartZ52mKWnT4OMeta || {},
    alias: cartZ52mKWnT4OMeta?.alias || [],
    redirect: cartZ52mKWnT4OMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: indexc57PL3wBoqMeta?.name ?? "checkout",
    path: indexc57PL3wBoqMeta?.path ?? "/checkout",
    meta: indexc57PL3wBoqMeta || {},
    alias: indexc57PL3wBoqMeta?.alias || [],
    redirect: indexc57PL3wBoqMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: contactUsvA3rY0c5suMeta?.name ?? "contactUs",
    path: contactUsvA3rY0c5suMeta?.path ?? "/contactUs",
    meta: contactUsvA3rY0c5suMeta || {},
    alias: contactUsvA3rY0c5suMeta?.alias || [],
    redirect: contactUsvA3rY0c5suMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cNsoiHsbpqMeta?.name ?? "custom-id",
    path: _91id_93cNsoiHsbpqMeta?.path ?? "/custom/:id()",
    meta: _91id_93cNsoiHsbpqMeta || {},
    alias: _91id_93cNsoiHsbpqMeta?.alias || [],
    redirect: _91id_93cNsoiHsbpqMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqsf1VX31ouVMeta?.name ?? "faq",
    path: faqsf1VX31ouVMeta?.path ?? "/faq",
    meta: faqsf1VX31ouVMeta || {},
    alias: faqsf1VX31ouVMeta?.alias || [],
    redirect: faqsf1VX31ouVMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexY9Jn2MBYlRMeta?.name ?? "index",
    path: indexY9Jn2MBYlRMeta?.path ?? "/",
    meta: indexY9Jn2MBYlRMeta || {},
    alias: indexY9Jn2MBYlRMeta?.alias || [],
    redirect: indexY9Jn2MBYlRMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBdm4q57I8UMeta?.name ?? "login",
    path: loginBdm4q57I8UMeta?.path ?? "/login",
    meta: loginBdm4q57I8UMeta || {},
    alias: loginBdm4q57I8UMeta?.alias || [],
    redirect: loginBdm4q57I8UMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placedoG2q02WJFNMeta?.name ?? "order-placed",
    path: order_45placedoG2q02WJFNMeta?.path ?? "/order-placed",
    meta: order_45placedoG2q02WJFNMeta || {},
    alias: order_45placedoG2q02WJFNMeta?.alias || [],
    redirect: order_45placedoG2q02WJFNMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: contentpLiGnl4G3VMeta?.name ?? "pages-key-content",
    path: contentpLiGnl4G3VMeta?.path ?? "/pages/:key()/content",
    meta: contentpLiGnl4G3VMeta || {},
    alias: contentpLiGnl4G3VMeta?.alias || [],
    redirect: contentpLiGnl4G3VMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failed882IBCKblCMeta?.name ?? "payment-failed",
    path: payment_45failed882IBCKblCMeta?.path ?? "/payment-failed",
    meta: payment_45failed882IBCKblCMeta || {},
    alias: payment_45failed882IBCKblCMeta?.alias || [],
    redirect: payment_45failed882IBCKblCMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionVo9bCRwYg6Meta?.name ?? "processing-transaction",
    path: processing_45transactionVo9bCRwYg6Meta?.path ?? "/processing-transaction",
    meta: processing_45transactionVo9bCRwYg6Meta || {},
    alias: processing_45transactionVo9bCRwYg6Meta?.alias || [],
    redirect: processing_45transactionVo9bCRwYg6Meta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cXNeSq41H8Meta?.name ?? "products-id",
    path: _91id_93cXNeSq41H8Meta?.path ?? "/products/:id()",
    meta: _91id_93cXNeSq41H8Meta || {},
    alias: _91id_93cXNeSq41H8Meta?.alias || [],
    redirect: _91id_93cXNeSq41H8Meta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcLhTb11Z4hMeta?.name ?? "products",
    path: indexcLhTb11Z4hMeta?.path ?? "/products",
    meta: indexcLhTb11Z4hMeta || {},
    alias: indexcLhTb11Z4hMeta?.alias || [],
    redirect: indexcLhTb11Z4hMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerma6WvvbFY5Meta?.name ?? "register",
    path: registerma6WvvbFY5Meta?.path ?? "/register",
    meta: registerma6WvvbFY5Meta || {},
    alias: registerma6WvvbFY5Meta?.alias || [],
    redirect: registerma6WvvbFY5Meta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetZjwCewCHReMeta?.name ?? "reset",
    path: resetZjwCewCHReMeta?.path ?? "/reset",
    meta: resetZjwCewCHReMeta || {},
    alias: resetZjwCewCHReMeta?.alias || [],
    redirect: resetZjwCewCHReMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticlWrdbQlwIgMeta?.name ?? "static",
    path: staticlWrdbQlwIgMeta?.path ?? "/static",
    meta: staticlWrdbQlwIgMeta || {},
    alias: staticlWrdbQlwIgMeta?.alias || [],
    redirect: staticlWrdbQlwIgMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verifyOmWjXVY23VMeta?.name ?? "verify",
    path: verifyOmWjXVY23VMeta?.path ?? "/verify",
    meta: verifyOmWjXVY23VMeta || {},
    alias: verifyOmWjXVY23VMeta?.alias || [],
    redirect: verifyOmWjXVY23VMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlistKpl0Nx3aTqMeta?.name ?? "wishlist",
    path: wishlistKpl0Nx3aTqMeta?.path ?? "/wishlist",
    meta: wishlistKpl0Nx3aTqMeta || {},
    alias: wishlistKpl0Nx3aTqMeta?.alias || [],
    redirect: wishlistKpl0Nx3aTqMeta?.redirect,
    component: () => import("/home/runner/work/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue").then(m => m.default || m)
  }
]