import { defineStore } from "pinia";
import { useWishListStore } from "../Features/ProductManagement/wishListStore";
import {
  EmailRegisterFormData,
  UpdateProfileFormData,
} from "~/types/UserManagement/formData";
import {
  UserState,
  SaveUserResponse,
  UserLoginResponse,
} from "~~/types/UserManagement/user";
import paths from "~/constants/paths";
import { useOrdersStore } from "~/store/Features/UserManagement/ordersStore";
import tenantConfig from "~/tenant-config.json";

export const useUserStore = defineStore("user-store", () => {
  const user = ref<UserState | null>(null);

  const router = useRouter();
  const hsToken = useCookie("hs-token");

  const wishListStore = useWishListStore();
  const ordersStore = useOrdersStore();

  const initUser = async (baseUrl: string) => {
    if (hsToken.value) {
      try {
        const { res } = await useApi<SaveUserResponse>(
          `/api/v1/me`,
          {
            authToken: hsToken.value,
          },
          baseUrl,
        );

        if (res) {
          user.value = {
            ...res.data,
            token: hsToken.value,
          };
        }

        wishListStore.initWishlist(baseUrl);
        ordersStore.fetchActiveOrders(baseUrl);
        ordersStore.fetchInActiveOrders(baseUrl);
      } catch (err) {
        logout();
        router.push(paths.login);
      }
    }
  };

  const login = async (baseUrl: string, firebase_token: string) => {
    const { res } = await useApi<UserLoginResponse>(`/api/v1/login`, {
      method: "POST",
      body: {
        firebase_token_string: firebase_token,
        device_name: "postman",
      },
      baseUrl,
    });

    if (res?.data) {
      user.value = {
        ...res.data.user,
        token: res.data.token,
      };

      hsToken.value = res.data.token;
    }
  };

  const register = async (
    userData: EmailRegisterFormData,
    firebase_token: string,
  ) => {
    const { res } = await useApi<UserLoginResponse>("/api/v2/register", {
      method: "POST",
      body: {
        firebase_token_string: firebase_token,
        device_name: "postman",
        firebase_key: tenantConfig.firebase,
        mobile: userData.mobile || "",
        country_code: userData.country_code || "",
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
      },
    });

    if (res?.data) {
      user.value = {
        ...res.data.user,
        token: res.data.token,
      };

      hsToken.value = res.data.token;
    }
  };

  const logout = () => {
    user.value = null;
    hsToken.value = null;
  };

  const update = async (
    baseUrl: string,
    userData: EmailRegisterFormData | UpdateProfileFormData,
  ) => {
    if (hsToken.value) {
      const { res } = await useApi<SaveUserResponse>(
        `/api/v1/me`,
        {
          method: "PUT",
          authToken: hsToken.value,
          body: {
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email.toLocaleLowerCase(),
            mobile: userData.mobile,
            country_code: userData.dial_code,
          },
        },
        baseUrl,
      );

      if (res?.data) {
        user.value = {
          ...user.value,
          ...res.data,
          token: hsToken.value,
        };
      }
    }
  };

  const deleteAccount = async (message: string) => {
    await useApi(`/api/v1/delete-user`, {
      method: "POST",
      authToken: hsToken.value,
      body: JSON.stringify({
        feedback: message,
      }),
    });
  };

  return {
    user,
    initUser,
    register,
    login,
    logout,
    update,
    deleteAccount,
  };
});
