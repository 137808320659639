import { Order } from "~/types/UserManagement/order";
import parsePriceString from "~/utils/ProductManagement/extractPrice";

export default function useGTM() {
  const gtm = useGtm();
  const nuxtApp = useNuxtApp();

  const enabled = computed(() =>
    nuxtApp.$appConfig?.integrations.includes("google_tag_integration"),
  );

  const trackEvent = (payload: any) => {
    if (enabled) {
      gtm?.trackEvent(payload);
    }
  };

  const trackPage = (pageName: string, pagePath: string) => {
    if (enabled) {
      gtm?.trackView(pageName, pagePath);
    }
  };

  const trackLogin = () => {
    trackEvent({
      event: "login",
      category: "Auth",
      action: "click",
      label: "login",
      noninteraction: false,
    });
  };

  const trackRegistration = () => {
    trackEvent({
      event: "sign_up",
      category: "Auth",
      action: "click",
      label: "register",
      noninteraction: false,
    });
  };

  const trackAddToCart = (id: string, type: string, price: string) => {
    const totalPrice = parsePriceString(price);
    trackEvent({
      event: "add_to_cart",
      category: "cart",
      action: "click",
      label: "add to cart",
      items: [{ id, type }],
      currency: totalPrice?.currency,
      value: totalPrice?.value,
      noninteraction: false,
    });
  };

  const trackInitCheckout = (order: Order) => {
    const price = parsePriceString(order?.pricing.total_price);

    trackEvent({
      event: "begin_checkout",
      category: "cart",
      action: "click",
      label: "begin checkout",
      items: {
        products: order?.products ?? [],
        bundles: order?.bundles ?? [],
        variants: order?.variants ?? [],
      },
      currency: price?.currency,
      value: price?.value,
      noninteraction: false,
    });
  };

  const trackPurchase = (order: Order) => {
    const totalPrice = parsePriceString(order?.pricing.total_price);
    const shippingPrice = parsePriceString(order?.pricing.total_price);

    trackEvent({
      event: "purchase",
      category: "cart",
      action: "click",
      label: "purchase",
      items: {
        products: order?.products ?? [],
        bundles: order?.bundles ?? [],
        variants: order?.variants ?? [],
      },
      currency: totalPrice?.currency,
      value: totalPrice?.value,
      shipping: shippingPrice?.value,
      noninteraction: false,
    });
  };

  return {
    enabled,
    trackLogin,
    trackRegistration,
    trackInitCheckout,
    trackPurchase,
    trackPage,
    trackAddToCart,
  };
}
