import tenantConfigJson from "~/tenant-config.json";
import { useUserStore } from "~/store/common/userStore";
import { useCartStore } from "~/store/Features/Checkout/cartStore";
import type {
  AppConfigs,
  AppConfigsResponse,
  Features,
} from "~/types/Common/appConfigs";

type ProvidedType =
  | {
      appConfig?: AppConfigs;
      baseUrl: string;
      color?: string;
      theme?: number;
      features?: Features;
    }
  | undefined;

export default defineNuxtPlugin(async () => {
  let provided: ProvidedType = undefined;

  const userStore = useUserStore();
  const cartStore = useCartStore();
  const env = useRuntimeConfig();
  const baseUrlCookie = useCookie("baseUrl");
  const tenantAuthCookie = useCookie("tenantAuth");

  async function fetchAppConfigs(baseUrl: string) {
    const { data: config } = await useAsyncData("config", async () => {
      const { res } = await useApi<AppConfigsResponse>(
        "/api/v1/app_configs",
        {},
        baseUrl,
      );
      return res;
    });

    return config.value;
  }

  if (env.public.env.VERSION === "PUBLISHED") {
    const config = await fetchAppConfigs(tenantConfigJson.baseUrl);

    await userStore.initUser(tenantConfigJson.baseUrl);
    cartStore.initCart(tenantConfigJson.baseUrl);

    provided = {
      appConfig: config?.data,
      baseUrl: tenantConfigJson.baseUrl,
      color: tenantConfigJson.primaryColor,
      theme: config?.data.theme,
      features: config?.data.features,
    };
  } else if (env.public.env.VERSION === "PREVIEW") {
    if (tenantAuthCookie.value?.toString() == "true" && baseUrlCookie.value) {
      const configuration = await fetchAppConfigs(baseUrlCookie.value);

      await userStore.initUser(baseUrlCookie.value);
      await cartStore.initCart(baseUrlCookie.value);

      provided = {
        appConfig: configuration?.data,
        baseUrl: baseUrlCookie.value,
        color: configuration?.data.color,
        theme: configuration?.data.theme,
        features: configuration?.data.features,
      };
    }
  }
  return {
    provide: provided,
  };
});
