import tenantConfig from "~/tenant-config.json";

export default function useGAnalytics() {
  const nuxtApp = useNuxtApp();

  const enabled = computed(() =>
    nuxtApp.$appConfig?.integrations.includes("google_analytics_integration"),
  );

  const getGAnalyticsScript = () => {
    if (enabled.value && tenantConfig?.propertyId) {
      return [
        {
          async: true,
          src: `https://www.googletagmanager.com/gtag/js?id=${tenantConfig.propertyId}`,
        },
        {
          innerHTML: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${tenantConfig.propertyId}');
            `,
        },
      ];
    }
    return [];
  };

  return { getGAnalyticsScript };
}
