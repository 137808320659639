import { initializeApp } from "firebase/app";
import data from "../tenant-config.json";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const apiKey = data.firebase ?? config.FIREBASE_API_KEY;
  const firebaseConfig = {
    apiKey,
    // apiKey: config.FIREBASE_API_KEY,
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
});
