import type {
  Cart,
  CartResponse,
  UpdateCartBodyType,
} from "~/types/Checkout/Cart";

import { defineStore } from "pinia";
import { useUserStore } from "~/store/common/userStore";
import useMetaPixel from "~/composables/Common/useMetaPixel";
import { ProductTypes } from "~/constants/ProductManagement/productTypes";

export const useCartStore = defineStore("cart-store", () => {
  const cart = ref<Cart | null>(null);
  const cartId = useCookie("hs-cart");
  const userStore = useUserStore();
  const quantity = ref(1);

  const isCartEmpty = computed(
    () =>
      !(
        !!cart.value?.products.length ||
        !!cart.value?.variants.length ||
        !!cart.value?.bundles.length
      ),
  );

  const { trackAddToCart } = useMetaPixel();
  const { trackAddToCart: trackGTagAddToCart } = useGTM();

  function reset() {
    cartId.value = null;
    cart.value = null;
  }

  async function updateQuantity(
    type: ProductTypes,
    quantity: number,
    id: string,
  ) {
    if (!cart.value) {
      throw new Error("Cart is not initialized");
    }

    const body: UpdateCartBodyType = { quantity };
    if (cart.value?.id) body.cart_id = cart.value?.id;
    if (type) body[type] = id;

    const { res } = await useApi<CartResponse>(`/api/v1/my-cart`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (res?.data) cart.value = res.data;
  }

  async function initCart(baseUrl: string) {
    if (cartId.value) {
      if (userStore.user && userStore.user?.token) {
        const { res } = await useApi<CartResponse>(
          `/api/v1/my-cart?cart_id=${cartId.value}`,
          {
            authToken: userStore.user?.token,
          },
          baseUrl,
        );
        if (res?.data) cart.value = res.data;
      } else {
        try {
          const { res } = await useApi<CartResponse>(
            `/api/v1/my-cart?cart_id=${cartId.value}`,
            {},
            baseUrl,
          );
          if (res?.data) cart.value = res.data;
        } catch (err) {
          console.error("Failed to initialize cart:", err);
          cart.value = null;
        }
      }
    } else {
      cart.value = null;
    }
  }

  async function addToCart(
    type: ProductTypes,
    id: string,
    quantity: number,
    price: string,
    others?: any,
  ) {
    const body = new FormData();
    body.append(type, id);
    body.append("quantity", quantity.toString());
    if (cart.value?.id) {
      body.append("cart_id", cart.value?.id);
    }
    if (others) {
      Object.keys(others).forEach((key) => {
        body.append(key, others[key]);
      });
    }
    const { res } = await useApi<CartResponse>(`/api/v1/add-to-cart`, {
      body: body,
      method: "POST",
    });
    if (res?.data) {
      cart.value = res.data;
      cartId.value = res.data.id;
    }
    trackAddToCart(id, type, price);
    trackGTagAddToCart(id, type, price);
  }

  return {
    cart,
    quantity,
    isCartEmpty,
    reset,
    updateQuantity,
    initCart,
    addToCart,
  };
});
