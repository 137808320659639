import WebFont from "webfontloader";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("app:mounted", () => {
    WebFont.load({
      google: {
        families: [
          "Aref Ruqaa",
          "Reem Kufi",
          "Vibes",
          "Tajawal",
          "El Messiri",
          "Marhey",
          "Emilys+Candy",
          "Italianno",
          "Elsie",
          "Bokor",
          "Fondamento",
          "Bilbo",
          "Henny Penny",
          "The Nautigal",
          "Playfair Display",
          "Sigmar One",
        ],
      },
    });
  });

  return {
    provide: {
      WebFont,
    },
  };
});
