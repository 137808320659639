import { SingleProduct } from "~/types/ProductManagement/Product";
import { Order } from "~/types/UserManagement/order";
import parsePriceString from "~/utils/ProductManagement/extractPrice";

export default function useMetaPixel() {
  const nuxtApp = useNuxtApp();

  const enabled = computed(() =>
    nuxtApp.$appConfig?.integrations.includes("facebook_pixel_integration"),
  );

  const trackEvent = (event: string, payload?: any) => {
    if (enabled.value) {
      //@ts-ignore
      nuxtApp.$fbq("track", event, payload);
    }
  };

  const trackPage = () => {
    trackEvent("PageView");
  };

  const trackAddToCart = (itemId: string, itemType: string, value: string) => {
    const price = parsePriceString(value);

    trackEvent("AddToCart", {
      content_ids: [itemId],
      content_type: itemType,
      currency: price?.currency,
      value: price?.value,
    });
  };

  const trackAddToWishList = (productId: string) => {
    trackEvent("AddToWishlist", {
      content_ids: [productId],
      content_name: "product",
    });
  };

  const trackRegistration = () => {
    trackEvent("CompleteRegistration");
  };

  const trackViewContent = (product?: SingleProduct) => {
    if (product) {
      trackEvent("ViewContent", {
        content_type: "product",
        content_ids: [product.id],
        content_name: product.title,
      });
    }
  };

  const trackInitCheckout = (order: Order) => {
    const price = parsePriceString(order.pricing.total_price);
    const items = [
      ...(order?.products ?? []),
      ...(order?.variants ?? []),
    ]?.reduce<any>((acc, item) => {
      return [
        ...acc,
        {
          id: item.id,
          name: item.title,
          quantity: item.quantity,
        },
      ];
    }, []);
    trackEvent("InitiateCheckout", {
      contents: items,
      currency: price?.currency,
      value: price?.value,
    });
  };

  const trackPaymentInfo = () => {
    trackEvent("AddPaymentInfo");
  };

  const trackPurchase = (order: Order) => {
    const price = parsePriceString(order?.pricing.total_price);

    const items = [
      ...(order?.products ?? []),
      ...(order?.variants ?? []),
    ]?.reduce<any>((acc, item) => {
      return [
        ...acc,
        {
          id: item.id,
          name: item.title,
          quantity: item.quantity,
        },
      ];
    }, []);
    trackEvent("Purchase", {
      contents: items,
      currency: price?.currency ?? "",
      value: price?.value ?? 0,
    });
  };

  const trackContacts = () => {
    trackEvent("Contact");
  };

  return {
    enabled,
    trackViewContent,
    trackAddToCart,
    trackPurchase,
    trackAddToWishList,
    trackRegistration,
    trackInitCheckout,
    trackPaymentInfo,
    trackContacts,
    trackPage,
  };
}
