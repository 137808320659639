export default defineNuxtRouteMiddleware(() => {
  const userAgent = navigator.userAgent || navigator.vendor;
  const { isInAppBrowser } = useInAppBrowser();

  isInAppBrowser.value =
    userAgent.includes("Instagram") ||
    userAgent.includes("FBAN") ||
    userAgent.includes("FBAV");

  if (isInAppBrowser.value) {
    const url = window.location.href.replace(/^https?:\/\//, "");
    const isAndriod = userAgent.includes("Android");
    const isIOS = userAgent.includes("AppleWebKit");

    // if (isAndriod) {
    //   window.location.href = `intent://${url}#Intent;scheme=https;package=com.android.chrome;end`;
    // } else if (isIOS) {
    //   window.location.href = `safari-https://${url}`;
    // } else {
    //   window.location.href = url;
    // }
  }
});
