export type LangType = {
  code: string;
  name: string;
  iso: string;
  dir: "rtl" | "ltr";
};
import { changeLocale } from "@formkit/vue";

export default function useLocale() {
  const { localeProperties, locales, setLocale } = useI18n();
  const localeCookie = useCookie("locale-cookie");

  const currentLocale = computed(
    () => localeProperties.value as unknown as LangType,
  );

  const availableLocales = computed(
    () => locales.value as unknown as LangType[],
  );

  const setLang = (lang: LangType, refreshPage: boolean = false) => {
    if (availableLocales.value.find((item) => item.code === lang.code)) {
      localeCookie.value = lang.code;
      if (!refreshPage) {
        setLocale(lang.code);
        changeLocale(lang.code);
        document.documentElement.setAttribute("dir", lang.dir);
      } else {
        reloadNuxtApp({ force: true });
      }
    } else {
      throw new Error(
        `'${lang.code} is not available. Check i18n configurations'`,
      );
    }
  };

  const initLocale = () => {
    if (localeCookie.value) {
      const storedCode = availableLocales.value.find(
        (item) => item.code === localeCookie.value,
      );
      if (storedCode) {
        setLang(storedCode);
      }
    } else {
      setLang(currentLocale.value);
    }
  };

  return { currentLocale, availableLocales, setLang, initLocale };
}
