import paths from "~/constants/paths";

export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();
  const cookie = useCookie("tenantAuth");
  const user = useCookie("hs-token");
  const router = useRouter();

  // Preview Global Gaurd
  if (
    config.public.env.VERSION == "PREVIEW" &&
    to.name != "tenantLogin" &&
    !cookie.value
  ) {
    return navigateTo("/tenantLogin");
  }
  if (
    config.public.env.VERSION == "PREVIEW" &&
    to.name == "tenantLogin" &&
    cookie.value
  ) {
    return navigateTo("/");
  }

  // UnKnown path Global Gaurd
  if (router.resolve(to.path).name == undefined) {
    return navigateTo("/404");
  }

  //
  if (router.resolve(to.fullPath).fullPath.includes("fbclid")) {
    const regex = /[?&]fbclid=[^&#]+/g;
    router.push(router.resolve(to.fullPath).fullPath.replace(regex, ""));
  }

  // Auth Global Gaurd
  if (to.path === paths.login && user.value) {
    return navigateTo("/");
  }
});
