export type PriceObject = { currency: string; value: number };

export default function parsePriceString(price?: string) {
  // Trim the input to remove leading and trailing whitespace
  price = price?.trim();

  // Regular expression to match the currency and value, allowing commas in the value
  const regex =
    /^([A-Z]{3})?\s*([0-9]{1,3}(?:,[0-9]{3})*(?:\.[0-9]{1,2})?)\s*([A-Z]{3})?$/;

  // Match the input string against the regular expression
  const match = price?.match(regex);

  if (match) {
    // Extract the currency and value from the matched groups
    let currency = match[1] || match[3];

    // Remove commas from the value and parse it as a float
    let value = parseFloat(match[2].replace(/,/g, ""));

    // Return the object with currency and value
    return { currency, value };
  } else {
    // Return null if the input string doesn't match the expected format
    return null;
  }
}
